import React, { useCallback } from 'react'
import classes from './Modal.module.css'
import H1 from '../H1/H1'
import { Button } from '../Button/Button'
import Icons from '../Icons/Icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const Modal = ({children, modalIsActive, setModalIsActive}) => {

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: '',
      fullName: '',
      email: '',
      message: '',
    }
  })

  const { t } = useTranslation()

  const getChangeHandlerWithValue = useCallback(name => value => {
    setValue(name, value.target.value)
    // if (canBeTriggered) trigger(name)
  }, [])

  // const fetchFormData = async (formData) => {
  //   try {
  //     await fetch('http://site-denis.morskiekrylya.ru:5000/mail', {
  //       method: 'POST',
  //       body: formData,
  //       headers: new Headers({'content-type': 'application/json'}),
  //     }).then((response) => console.log(response))
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  const sendEmail = async (data) => {
    try {
      let message = ``
      if (data.message) {
        {data.message.split(/[\r\n]+/).map((line) =>
          message += `%0D%0A${line}`
        )}
      }
      window.location.href = 'mailto:fix@akshipbrokers.com?subject=Письмо с контактами от компании ' + data.companyName + '&body=' + 'Название компании - ' + data.companyName + '%0D%0AФИО - ' + data.fullName + '%0D%0Aemail - ' + data.email + `%0D%0A%0D%0A Сообщение: ${message}`
    } catch (e) {
      console.log(e)
    }
  }

  const textAreaAutoGrow = async (element) => {
    element.style.height = '67px'
    element.style.height = element.scrollHeight + 5 + 'px'
  }

  const onSubmit = useCallback((data) => {
    // fetchFormData(JSON.stringify(data))
    sendEmail(data)
  }, [])

  return (
    <div className={`${classes['modal-container']}`} onClick={() => setModalIsActive(false)}>
      <Icons
        name='x'
        className={`${classes['modal-close']}`}
        onClick={() => setModalIsActive(false)}
      />
      <div
        className={`${classes['modal']}`}
        onClick={(e) => e.stopPropagation()}
      >
        <H1 className={`${classes['modal-title']}`}>{t("modal-getContact-title")}</H1>
        <form
          id='modal-form'
          className={`${classes['modal-data']}`}
          onSubmit={handleSubmit(onSubmit)}
          // onChange={(e) => e.preventDefault()}
          // action='mailto:belugin-denis2@rambler.ru'
          // method='POST'
        >
          <label className={classes['modal-data-label']}>
            <input
              className={`${classes['modal-data-input']}`}
              placeholder={t("modal-getContact-input-companyName-title")}
              {...register("companyName", {
                required: t("modal-getContact-input-companyName-errorMessage"),
                pattern: {
                  // value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/,
                  // message: "Введите название компании",
                }
              })}
              onChange={getChangeHandlerWithValue("companyName")}
              // onKeyUp={(e) => setEmail(e.target.value)}
            />
            <div className={classes['modal-data-validationMessage']}>
              {errors.companyName && errors.companyName.message}
            </div>
          </label>
          <label className={classes['modal-data-label']}>
            <input
              className={`${classes['modal-data-input']}`}
              placeholder={t("modal-getContact-input-fullName-title")}
              {...register("fullName", {
                required: t("modal-getContact-input-fullName-errorMessage"),
                pattern: {
                  // value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/,
                  // message: "Введите название компании",
                }
              })}
              onChange={getChangeHandlerWithValue("fullName")}
              // onKeyUp={(e) => setEmail(e.target.value)}
            />
            <div className={classes['modal-data-validationMessage']}>
              {errors.fullName && errors.fullName.message}
            </div>
          </label>
          <label className={classes['modal-data-label']}>
            <input
              className={`${classes['modal-data-input']}`}
              placeholder={t("modal-getContact-input-email-title")}
              {...register("email", {
                required: t("modal-getContact-input-email-errorMessage"),
                pattern: {
                  // value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/,
                  // message: "Введите название компании",
                }
              })}
              onChange={getChangeHandlerWithValue("email")}
              // onKeyUp={(e) => setEmail(e.target.value)}
            />
            <div className={classes['modal-data-validationMessage']}>
              {errors.email && errors.email.message}
            </div>
          </label>
          <label className={classes['modal-data-label']}>
            <textarea
              className={`${classes['modal-data-input']} ${classes['modal-data-textarea']}`}
              placeholder={t("modal-getContact-input-message-title")}
              onChangeCapture={(e) => textAreaAutoGrow(e.target)}
              {...register("message", {
                // required: "Сообщение - обязательное поле",
                pattern: {
                  // value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/,
                  // message: "Введите название компании",
                }
              })}
              onChange={getChangeHandlerWithValue("message")}
              // onKeyUp={(e) => setEmail(e.target.value)}
            />
            <div className={classes['modal-data-validationMessage']}>
              {errors.message && errors.message.message}
            </div>
          </label>
          <Button
            className={`${classes['modal-button']}`}
            type='small'
            buttonType='submit'
            form='modal-form'
          >
            {t("modal-getContact-submit")}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Modal