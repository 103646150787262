export const MAIN_ROUTE = '/'

export const white1 = '#FFFCF8';
export const white2 = '#F7F5F1';
export const white3 = '#ECE6DB';
export const black = '#252525';
export const primary = '#0F37A0';
export const secondary = '#5B74B4';
export const orange = '#DD7700';

export const windowWidthTablet = 834;
export const windowWidthMobile = 500;
export const windowWidthMobile2 = 390;

export const languages = [
  { label: "Русский язык", code: 'ru'},
  { label: "English", code: 'en'},
]