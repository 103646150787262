import React from 'react'
import classes from './Wrapper.module.css'

const Wrapper = ({wrapperRef, ...params}) => {
  return (
    <div ref={wrapperRef} className={`${classes.container} ${params.className}`}>
      <div className={classes["content"]}>
        {params.children}
      </div>
    </div>
  )
}

export default Wrapper