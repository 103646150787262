import React from 'react'
import classes from './H1.module.css'

const H1 = ({className, h1Ref, ...params}) => {
  return (
    <h1 className={`${classes['h1-desktop']} ${className}`} ref={h1Ref}>
      {params.children}
    </h1>
  )
}

export default H1