import React from 'react'
import classes from './Button.module.css'

export const Button = ({className, type = 'primary', disabled, buttonRef, buttonType = 'button', ...props}) => {
  let primary = classes['button__primary']
  let secondary = classes['button__secondary']
  let small = classes['button__small']
  let classType = type === 'secondary' ? secondary : type === 'primary' ? primary : type === 'small' ? small : primary
  const classNames = className ? `${classes.button} ${className} ${classType}` : `${classes.button} ${classType}`
  return (
    <button className={classNames} disabled={disabled} ref={buttonRef} type={buttonType} {...props}>
      {props.children}
    </button>
  )
}