import React, { useEffect, useState } from 'react'
import classes from './NavBar.module.css'
import { ReactComponent as FlagEN } from '../../assets/flag-en.svg'
import { ReactComponent as FlagRU } from '../../assets/flag-ru.svg'
import { ReactComponent as LogoTitle } from '../../assets/logo-title.svg'
import { ReactComponent as LogoSubtitle } from '../../assets/logo-subtitle.svg'
import { HashLink } from 'react-router-hash-link'
import { languages, MAIN_ROUTE, windowWidthMobile, windowWidthMobile2, windowWidthTablet } from '../../utils/consts'
import Icons from '../Icons/Icons'
import { useTranslation } from 'react-i18next'
import { setStorageWithExpiry } from '../../utils/setStorageWithExpiry'
import dayjs from 'dayjs'
import { Select } from 'antd'

const NavBar = () => {
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)
  const [isWindowWidthMobile2, setIsWindowWidthMobile2] = useState(window.innerWidth <= windowWidthMobile2)
  const [menuPopupIsActive, setMenuPopupIsActive] = useState(false)
  const { i18n, t } = useTranslation()

  const handleLanguageChange = (e) => {
    const newLang = e
    setStorageWithExpiry('lang', newLang, dayjs().add(1, 'years').format())
    i18n.changeLanguage(newLang)
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY
    const yOffset = -120
    window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'})
  }

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
    setIsWindowWidthMobile2(window.innerWidth <= windowWidthMobile2)
  }

  useEffect(() => {
    window.addEventListener('resize', onWindowWidthChange)
    return () => window.removeEventListener('resize', onWindowWidthChange)
  }, [])

  return (
    <nav className={classes['bar']}>
      <div className={classes['content']}>
        <div className={classes['menu']}>
          <div className={`${classes['logo']}`}>
            <LogoTitle className={`${classes['logo-title']}`}/>
            <div className={classes['logo-divider']}></div>
            <LogoSubtitle className={`${classes['logo-subtitle']}`}/>
          </div>
        </div>
        <div className={classes['menu']}>
          {isWindowWidthMobile
          ?
            <div
              className={classes['links-burger-container']}
              onClick={() => setMenuPopupIsActive((menuPopupIsActive) => !menuPopupIsActive)}
            >
              {menuPopupIsActive
              ?
                <Icons
                  name='x'
                  className={`${classes['links-burger']} ${classes['links-burger__close']}`}
                />
              : 
                <Icons
                  name='burger'
                  className={`${classes['links-burger']}`}
                />
              }
            </div>
          :
            <div className={classes['links']}>
              <HashLink className={classes['link']} to={`${MAIN_ROUTE}#about`} scroll={el => scrollWithOffset(el)}>{t('navlink-about')}</HashLink>
              <HashLink className={classes['link']} to={`${MAIN_ROUTE}#contact`} smooth>{t('navlink-contacts')}</HashLink>
            </div>
          }
          <Select
            dropdownAlign={{ offset: [-105, 5] }}
            popupMatchSelectWidth={false}
            className={classes['select-language']}
            popupClassName={classes['select-language-popup']}
            optionFilterProp="children"
            optionLabelProp="label"
            value={i18n.language}
            onChange={(e) => handleLanguageChange(e)}
          >
            {languages.map(lang => 
              <Select.Option
                key={lang.code}
                value={lang.code}
                label={lang.code === 'en' ? <FlagEN className={`${classes['flag']}`}/> : lang.code === 'ru' ? <FlagRU className={`${classes['flag']}`}/> : lang.label}
              >
                {lang.code === 'en' ? <FlagEN className={`${classes['flag']}`}/> : lang.code === 'ru' ? <FlagRU className={`${classes['flag']}`}/> : lang.label} {lang.label}
              </Select.Option>
            )}
          </Select>
          {isWindowWidthMobile && menuPopupIsActive &&
            <div className={`${classes['menu-popup']}`}>
              <div className={`${classes['menu-popup-links']}`}>
                <HashLink className={classes['link']} to={`${MAIN_ROUTE}#about`} scroll={el => scrollWithOffset(el)} onClick={() => setMenuPopupIsActive(false)}>{t('navlink-about')}</HashLink>
                <HashLink className={classes['link']} to={`${MAIN_ROUTE}#contact`} smooth onClick={() => setMenuPopupIsActive(false)}>{t('navlink-contacts')}</HashLink>
              </div>
            </div>
          }
        </div>
      </div>
    </nav>
  )
}

export default NavBar